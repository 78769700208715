/* eslint-disable quote-props */

export default {
  auth: {
    register: 'Создать',
    login: 'Вход',
    registerReset: 'Скинуть пароль',
    detailsSubtitle: 'Введите свои данные',
    resetPasswordLink: 'Забыли пароль?',
    user: 'Имя пользователя',
    password: 'Пароль',
    confirmPassword: 'Повторить пароль',
    key: 'Лицензионный ключ',
    registerSubmit: 'Зарегистрироваться',
    resetSubmit: 'Скинуть',
    loginSubmit: 'Войти',
    carousel: {
      title1: 'Облако и в Он-сайт',
      text1: 'Политика безопасности не позволяет хранить данные и использовать облачные сервисы? С Webitel вы можете построить контакт-центр на своей площадке!',

      title2: 'Мультиканальность и Омниканальность',
      text2: 'Будьте всегда и везде на связи со своими клиентами!  IVR-меню любой сложности,  входящие, исходящие кампании взаимодействия или сообщение в социальные сети - теперь просто как никогда!',

      title3: 'Отказоустойчивая инфраструктура',
      text3: 'Больше никаких простоев и потерь данных. Быстрое масштабирование и отказоустойчивая архитектура позволяет обслуживать одновременно более 1000 обращений клиентов в минуту с разных каналов коммуникаций.',

      title4: 'Дизайн',
      text4: 'Новый минималистичный интерфейс с максимально удобной для пользователя админ-панелью, позволяющей иметь оперативный доступ ко всем необходимым функциям. Меньше кликов - больше возможностей.',

      title5: 'Новый модуль работы с операторскими группами',
      text5: 'Максимально эффективное распределение звонков. Оператор может обладать сразу несколькими навыками для участия в разных кампаниях. Клиента обслуживают только профессионалы своего дела.',

      title6: 'Настройка прав доступа',
      text6: 'Новый модуль управления правами - новые возможности для контроля. Вы можете назначать права не только на разделы, но и на действия пользователей, при этом отслеживая вносимые изменения. ',
    },
  },

  header: {
    logout: 'Выйти',
    docs: 'Справка',
  },

  appNavigator: {
    title: 'Приложения Webitel',
    admin: 'Admin',
    agent: 'Agent Workspace',
    supervisor: 'Supervisor Workspace',
    audit: 'Audit',
    history: 'Call History',
    grafana: 'Grafana',
  },

  settings: {
    settings: 'Настройки',
    changePassword: 'Изменить пароль',
    language: 'Язык',
    webPhone: 'Web-телефон',
    useWebPhone: 'Использовать Web-телефон',
    useStun: 'Использовать STUN',
  },

  validation: {
    email: 'Введите адрес электронной почты',
    required: 'Обязательное поле для заполнения',
    sameAs: 'Неверный пароль',
    gatewayHostValidator: 'Should look like IPv4 or FQDN',
    ipValidator: 'Should look like IPv4',
    macValidator: 'Should look like MAC',
    minValue: 'Значение должно быть не менее',
    maxValue: 'Значение должно быть не слишком большим',
    numeric: 'Должны быть цифры',
    requiredArrayValue: 'Поле не должно быть пустым',
    isPositiveValue: 'Значение должно быть больше нуля',
    cron: 'Некорректное cron-выражение',
  },

  nav: {
    directory: {
      directory: 'Каталог',
    },
    routing: {
      routing: 'Маршрутизация',
    },
    lookups: {
      lookups: 'Справочники',
    },
    ccenter: {
      ccenter: 'Контакт-центр',
    },
    integrations: {
      integrations: 'Интеграции',
    },
    permissions: {
      permissions: 'Разрешения',
    },
  },

  home: {
    dashboard: 'Отчеты',
    welcomeText: 'Приветствуем!',
    news: 'Новости',
  },

  objects: {
    all: 'Все {entity}',
    administration: 'Управление',
    general: 'Общее',
    new: 'Новый объект',
    add: 'Добавить',
    create: 'Создать',
    read: 'Читать',
    edit: 'Редактировать',
    delete: 'Удалить',
    save: 'Сохранить',
    saved: 'Сохранено',
    saveAs: 'Сохранить как нового',
    close: 'Закрыть',
    name: 'Имя',
    description: 'Описание',
    user: 'Пользователь',
    users: 'Пользователи',
    action: 'Action',
    allow: 'Allow',
    password: 'Пароль',
    history: 'История',
    copy: 'Копировать',
    copied: 'Скопировано!',
    createdAt: 'Создано',
    createdBy: 'Кем создан',
    importCSV: 'Импортировать CSV',
    online: 'Онлайн',
    offline: 'Оффлайн',
    on: 'On',
    off: 'Off',
    ok: 'Ok',
    enabled: 'Включено',
    status: 'Статус',
    from: 'От',
    to: 'До',
    importJSON: 'Импортировать JSON',
    addNew: 'Добавить',
    provider: 'Провайдер',
    default: 'По умолчанию',
    service: 'Сервис',
    tts: 'Text-to-Speech',
    stt: 'Speech-to-Text',
    key: 'Ключ',
    ID: 'ID',
    email: 'Адрес электронной почты',
    updatedAt: 'Изменено',

    deleteConfirmation: {
      title: 'Подтвердите удаление',
      askingAlert: 'Вы уверенны, что хотите удалить {count} запись? | Вы уверенны, что хотите удалить {count} записей?',
      undoneActionAlert: 'Это действие не может быть отменено.',
      deleteAll: 'ВСЕ',
    },
    CSV: {
      skipHeaders: 'Свернуть заголовки',
      charSet: 'Char set',
      separator: 'Разделитель',
      CSVColumn: 'CSV поле',
      fieldName: 'Название поля',
      clearMember: 'Очистить абонента',
    },
    generalInfo: 'Общая информация',
    objectHeader: {},
    directory: {
      directory: 'Каталог',
      passwordInfo: 'Введите значение, если хотите перезаписать (или создать новый) пароль',
      users: {
        users: 'Пользователь | Пользователи',
        allUsers: 'Все пользователи',
        searchPlaceholder: 'Имя, пользователь, номера телефона, статус',
        login: 'Логин',
        extensions: 'Внутренний номер',
        state: 'Статус',
        DnD: 'DnD',
        status: 'Статус',
        roles: 'Роли',
        tokens: 'Токены',
        usage: 'Использование',
        roleAdmin: 'Администратор',
        license: 'Лицензия',
        devices: 'Устройство',
        defaultDevice: 'Устройство по умолчанию (из списка выбраных устройств)',
        deviceNotFound: 'Не смогли найти свое устройство?',
        createNewDevice: 'Добавить новое',
        variables: 'Переменные',
        varKey: 'Ключ',
        varVal: 'Значение',
        token: 'Токен | Токены',
        tokenPopupHeader: 'Ваш токен-ключ был успешно создан!',
        tokenPopupText: 'Загрузите файл с ключом сейчас или скопируйте его для дальнейшего использования. Если вы не загрузите файл ключа сейчас, вы не сможете  получить свой токен-ключ снова. Для вашей безопасности никому не передавайте  свой токен-ключ.',
        tokenPopupCopy: 'Копировать',
        tokenPopupSave: 'Сохранить  в формате TXT',
      },
      license: {
        customers: 'Пользователи',
        license: 'Лицензия | Лицензии',
        licenseId: 'ID',
        allCustomers: 'Все пользователи',
        allLicenses: 'Все лицензии',
        serial: 'Серия',
        product: 'Продукт',
        validFrom: 'Действительно с',
        validTill: 'Действительно до',
        used: 'Использовано',
        limit: 'Лимит',
        status: 'Статус',
        competitive: 'Конкурентная',
        searchPlaceholder: 'Серия, продукт',
        domain: 'Домен',
        daysToExpire: {
          '0': 'Истечение срока действия',
          '30': '30 или меньше',
          '90': '90 или меньше',
          days: ' дней',
        },
        importLicense: 'Загрузить лицензию',
        licenseKey: 'Лицензионный ключ',
      },
      devices: {
        devices: 'Устройство | Устройства',
        allDevices: 'Все устройства',
        newDevice: 'Новое устройство',
        newDeviceDescription: 'Описание нового устройства',
        hotdeskDeviceSettings: 'Настройка посменного использования устройства',
        searchPlaceholder: 'Название, пользователь, идентификатор & идентификатор компьютера',
        authId: 'Идентификатор',
        presence: 'Актуальный статус',
        hotdesk: 'Hot desking',
        hotdeskDevice: 'Hot desking устройство',
        hotdeskIncorrectInput: 'Неправильное имя хоста',
        hotdeskInputHint: 'Для имени хоста используйте правильные символы. Разрешены символы: A-Z, a-z, 0-9, -, _',
        deviceSettings: 'Настройки устройства',
        hostName: 'Имя хоста',
        phoneInfo: 'Информация о телефоне',
        phone: 'Телефон',
        vendor: 'Производитель',
        model: 'Модель',
        ipv4: 'IPv4',
        mac: 'MAC',
        deviceHistory: 'История пользования',
        loggedIn: 'В системе',
        loggedOut: 'Вышел из системы',
        state: {
          nonreg: 'Не зарегистрировано',
          reged: 'Зарегистрировано',
          ringing: 'Звонит',
          dialing: 'Набирает',
          dialog: 'В разговоре',
          onhold: 'На удержании',
        },
        passwordSetFromAccount: 'Если вы не установите пароль, он будет таким же, как аккаунт',
      },
    },
    permissions: {
      permissions: 'Разрешения',
      permissionsRole: 'Роль | Роли',
      allRoles: 'Все роли',
      searchPlaceholder: 'роль ..',
      roles: {
        permissions: {
          permissions: 'Право доступа роли | Права доступа ролей',
          add: 'Создавать',
          delete: 'Удалять',
          read: 'Выбирать',
          write: 'Редактировать',
          eavesdropCall: 'Прослушивать активный звонок',
          playbackRecordFile: 'Воспроизводить записи разговоров',
          exportDataGrid: 'Экспортировать данные',
          viewCdrPhoneNumbers: 'Просмотр незашифрованных номеров телефонов',
          manageUserRoles: 'Управление ролями пользователей',
          manageUserLicense: 'Управление лицензиями пользователей',
          addDescription: 'Предоставляет разрешение на создание объектов',
          deleteDescription: 'Предоставляет разрешение на удаление объектов',
          readDescription: 'Предоставляет разрешение на выбор объектов',
          writeDescription: 'Предоставляет разрешение на изменение  объектов',
          eavesdropCallDescription: 'Предоставляет разрешение на прослушивание активного разговора',
          playbackRecordFileDescription: 'Предоставляет разрешение на воспроизведение файлов с записями разговоров',
          exportDataGridDescription: 'Предоставляет разрешение на экспорт данных',
          viewCdrPhoneNumbersDescription: 'Предоставляет разрешение на отображение незашифрованных телефонных номеров',
          manageUserRolesDescription: 'Предоставляет разрешение на управление набором ролей пользователей',
          manageUserLicenseDescription: 'Предоставляет разрешение на управление набором пользовательских лицензий',
        },
        addPermission: 'Добавить право доступа для роли',
        usage: 'Использование',
        applicationsAccess: {
          applicationsAccess: 'Приложение | Приложения',
          access: 'Доступ',
        },
      },
      object: {
        object: 'Разделы',
        allObjects: 'Все разделы',
        ObAC: 'Управление действиями',
        RbAC: 'Управление записями',
        operations: 'Действия',
        searchPlaceholder: 'раздел ..',
        newPermissionRole: 'Новый владелец прав',
        rbacDefault: 'Права доступа по записям по умолчанию',
        grantor: 'Праводатель',
        grantee: 'Получатель',
        create: 'Создавать',
        delete: 'Удалять',
        accessMode: {
          '1': 'Запрещено',
          '2': 'Разрешено',
          '3': 'Управление',
        },
      },
    },
    lookups: {
      lookups: 'Справочники',
      skills: {
        skills: 'Навык | Навыки',
        capacity: 'Потенциал',
        minCapacity: 'Минимальный потенциал',
        minCapacityLessOrEqualToMaxCapacityValidator: 'Должно быть меньше или равно Максимальному потенциалу',
        maxCapacity: 'Максимальный потенциал',
        maxCapacityMoreOrEqualToMinCapacityValidator: 'Должно быть больше или равно Минимальному потенциалу',
        lvl: 'Уровень',
        agentSkills: 'Навыки оператора | Навыки операторов',
        allSkills: 'Все навыки',
        state: 'Состояние',
      },
      buckets: {
        buckets: 'Корзина | Корзины',
        allBuckets: 'Все корзины',
        addBucket: 'Добавить корзину',
      },
      blacklist: {
        blacklist: 'Список обзвона | Списки обзвона',
        allBlacklists: 'Все списки обзвона',
        number: 'Номер | Номера',
        newNumber: 'Новый номер',
        numbersCount: 'Номера',
      },
      media: {
        mediaFiles: 'Медиафайл| Медиафайлы',
        allMediaFiles: 'Все медиафайлы',
        createdAt: 'Создано',
        format: 'Формат',
        size: 'Размер',
        textToSpeech: 'Синтез речи',
        popupSettings: 'Настройки',
        lang: 'Язык',
        voice: 'Голос',
        token: 'Токен',
        key: 'Ключ',
        dragPlaceholder: 'Загрузка файлов',
        dragPlaceholderHere: 'здесь.',
        tts: {
          hint: 'Создать аудиофайл',
          textType: {
            textType: 'Тип текста',
            ssml: 'SSML',
          },
        },
      },
      regions: {
        regions: 'Площадка | Площадки',
        allRegions: 'Все площадки',
      },
      timezone: {
        timezone: 'Часовая зона | Часовые зоны',
      },
      calendars: {
        calendars: 'Календарь | Календари',
        allCalendars: 'Все календари',
        searchPlaceholder: 'Название, описание...',
        timezone: 'Часовой пояс',
        start: 'Начало',
        end: 'Конец',
        workWeek: 'Рабочая неделя',
        holidays: 'Выходной | Выходные',
        date: 'Дата',
        repeat: 'Повторить',
        fulltime: 'Временный',
        everyYear: 'Повторять каждый год',
        newHoliday: 'Новый выходной',
        mon: 'Понедельник',
        tue: 'Вторник',
        wed: 'Среда',
        thu: 'Четверг',
        fri: 'Пятница',
        sat: 'Суббота',
        sun: 'Воскресение',
      },
      communications: {
        communications: 'Тип связи| Типы связи',
        allCommunications: 'Все типы связи',
        addCommunication: 'Добавить связь',
        code: 'Код',
      },
      pauseCause: {
        pauseCause: 'Статусы операторов',
        allPauseCause: 'Все причины паузы оператора',
        limit: 'Лимит (мин)',
        min: 'Минут',
        allowAdmin: 'Администратор может изменять этот статус',
        allowSupervisor: 'Супервизор может изменять этот статус',
        allowAgent: 'Оператор может изменять этот статус',
      },
    },
    routing: {
      routing: 'Маршрутизация',
      ip: 'IP',
      protocol: 'Протокол',
      port: 'Порт',
      configuration: 'Конфигурация',
      schema: 'Схема звонка',
      json: 'JSON',

      flow: {
        flow: 'Схема',
        allFlowSchemas: 'Все схемы звонков',
        createFlowSelectionPopup: 'Создать новую схему',
        createNew: 'Создать новую схему?',
        editor: 'Редактор',
        diagram: {
          diagram: 'Диаграмма',
          description: 'Создать новую схему используя Webitel Flow Diagram',
        },
        code: {
          code: 'Код',
          description: 'Создать новую схему используя JSON-схему в редакторе кода',
        },
      },

      dialplan: {
        dialplan: 'Исходящая маршрутизация',
        dialplanRules: 'Правила исходящей маршрутизации',
        pattern: 'Номер назначения',
        dialplanRule: 'Правило исходящей маршрутизации',
        position: 'Позиция',
      },

      chatplan: {
        chatplan: 'Правило маршрутизации текстовых сообщений | Правила маршрутизации текстовых сообщений',
        allChatplans: 'Все правила маршрутизации текстовых сообщений',
      },

      gateways: {
        gateways: 'Шлюз | Шлюзы',
        allGateways: 'Все шлюзы',
        trunkingGateway: 'Многоканальный шлюз',
        registerGateway: 'Зарегистрировать шлюз',
        stateSuccess: 'Успешно',
        stateFailed: 'Неудачно',
        stateProgress: 'В процессе',
        stateNonreg: 'Не зарегистрирован',
        proxy: 'Прокси',
        searchPlaceholder: 'поиск шлюза...',
        host: 'Хост',
        trunkingACLTitle: 'Контроль доступа',
        trunkingACL: 'Многоканальный список доступа',
        hostnameTrunking: 'Сервер/Хост шлюза или IP',
        hostnameRegister: 'Регистратор/Сервер/Хост шлюза или IP',
        expire: 'Срок действия',
        authID: 'Имя пользователя',
        account: 'Аккаунт',
        outboundProxy: 'Адрес исходящего SIP-прокси',
        newGateway: 'Новый шлюз',
        newGatewayDescription: 'Описание нового шлюза',
        SIPregistrations: 'SIP-регистрация',
        SIPregistrationsDescription: 'Описание SIP-регистрации',
        SIPtrunking: 'SIP-транки',
        SIPtrunkingDescription: 'Описание SIP-транков',
      },

      chatGateways: {
        templates: {
          templates: 'Шаблоны',
          title: 'Анонимное имя абонента в Workspace',
          close: 'Сообщение завершения чата',
          join: 'Сообщение присоединения оператора',
          left: 'Сообщение отключения оператора',
        },
        chatGateways: 'Текстовый шлюз | Текстовые шлюзы',
        allChatGateways: 'Все текстовые шлюзы',
        telegramBot: {
          telegramBot: 'Telegram Бот',
        },
        telegramApp: {
          telegramApp: 'Telegram Приложение',
          signedAs: 'Вы авторизированы как',
          joinTelegram: 'Sign in to Telegram',
          metadata: {
            apiId: 'API идентификатор',
            apiHash: 'API хэш',
          },
        },
        infobip: {
          infobip: 'Infobip',
        },
        messenger: {
          messenger: 'Messenger',
          addOrRemovePages: 'Добавить или удалить страницы',
          accounts: 'Аккаунты',
          metadata: {
            clientId: 'App ID',
            clientSecret: 'App Secret',
          },
          facebook: {
            pages: 'Facebook страницы',
            subscription: 'Webhook подписки',
          },
          instagram: {
            instagram: 'Instagram',
            comments: 'Отслеживать комментарии',
            mentions: 'Отслеживать упоминания',
          },
        },
        viber: {
          viber: 'Viber',
          style: {
            style: 'Стиль',
            btnBackColor: 'Цвет фона кнопки',
            btnFontColor: 'Цвет текста кнопки',
          },
        },
        webchat: {
          webchat: 'Web chat',
          copyCode: 'Скопировать код',
          copyCodeHint: 'Необходимо перегенерировать и вставить код Виджета на сайт после каждой модификации настроек',
          metadata: {
            mediaMaxSize: 'Максимальный размер файла (Мб)',
          },
          view: {
            borderRadius: 'Вид кнопки',
            language: 'Язык',
            view: 'Вид',
            position: 'Размещение',
            btnColor: 'Цвет кнопки',
            logoUrl: 'URL логотипа',
            logoHint: 'Поддерживаемый формат логотипа - \'JPEG\' or \'PNG\', 24x24px',
            rounded: 'Круглая',
            square: 'Квадратная',
            right: 'Справа',
            left: 'Слева',
            static: 'Статическая',
          },
          chat: {
            chat: 'Чат',
            openTimeout: 'Включить задержку открытия',
            openTimeoutSec: 'Время задержки открытия',
          },
          appointment: {
            appointment: 'Заказ звонка',
            days: 'Количество дней',
            availableAgents: 'Свободные агенты',
            showEmailField: 'Показать поле ввода почты',
            showMessageField: 'Показать поле ввода сообщения',
            successMessage: 'Сообщение об успехе',
          },
          alternativeChannels: {
            alternativeChannels: 'Альтерантивные каналы',
            email: 'Электронная почта',
            whatsapp: 'WhatsApp',
            telegram: 'Telegram',
          },
        },
        uri: 'URI',
        newChatGateway: 'Новый текстовый шлюз',
        metadata: {
          apiKey: 'Api Key',
          number: 'Number',
          baseUrl: 'Base URL',
          sendApi: 'Send API',
          allowOrigin: 'Allow Origin',
          readTimeout: 'Read Timeout (sec)',
          writeTimeout: 'Write Timeout (sec)',
          handshakeTimeout: 'Handshake Timeout (sec)',
          messageSize: 'Message Size max (bytes)',
          botName: 'Bot Name',
          eventTypes: 'Event Types',
          telegramToken: 'Telegram BOT API Token',
          messengerApiUrl: 'Messenger API URL',
        },
      },

      callflow: {
        callflow: 'Конструктор звонка',
      },
    },
    ccenter: {
      ccenter: 'Контакт центр',
      agents: {
        agents: 'Оператор | Операторы',
        supervisors: 'Супервизор | Супервизоры',
        admins: 'Администратор | Администраторы',
        subordinates: 'Подчинённый оператор | Подчинённые операторы',
        allAgents: 'Все операторы',
        state: 'Статус',
        progressiveCount: 'Количество одновременных звонков',
        chatCount: 'Количество одновременных чатов',
        isSupervisor: 'Супервизор',
        stateTime: 'Длительность',
        addSkill: 'Добавить навык',
        addSubordinate: 'Добавить подчинённого оператора',
        statusHistory: 'История состояний',
        historyState: 'Состояние',
        historyChannel: 'Канал',
        historyFrom: 'С',
        historyTo: 'До',
        historyDuration: 'Длительность',
        payload: 'Причина',
        status: {
          online: 'Онлайн',
          offline: 'Оффлайн',
          pause: 'Пауза',
          breakOut: 'Принудительный перерыв',
        },
      },

      auditors: {
        auditors: 'Аудитор | Аудиторы',
      },

      teams: {
        teams: 'Команда| Команды',
        allTeams: 'Все команды',
        strategy: 'Стратегия',
        timing: 'Параметры дозвона',
        maxNoAnswer: 'Максимальное количество звонков без ответа',
        callTimeout: 'Длительность вызова',
        inviteChatTimeout: 'Время принятия чата',
        wrapUpTime: 'Длительность паузы между звонками',
        noAnswerDelayTime: 'Время ожидания при отсутствии ответа оператора',
        strategies: {
          random: 'Случайно',
          fewestCalls: 'Агент с наименьшим количеством звонков',
          leastTalkTime: 'Агент с наименьшим временем разговора',
          topDown: 'Сверху вниз',
          roundRobin: 'По порядку',
          roundRobinBucket: 'По порядку корзины',
          longestIdleAgent: 'Агент с наибольшим временем простоя',
          skillCapacity: 'По уровню навыка',
        },
      },

      members: {
        members: 'Абонент| Абоненты',
        allMembers: 'Все абоненты',
        addMember: 'добавить абонента',
        destination: 'Назначение',
        display: 'Отображение номера',
        priority: 'Приоритет',
        attempts: 'Попыток',
        resetMembers: {
          resetMembers: 'Перезапустить абонентов',
          description: 'Вы действительно хотите перезапустить повторный дозвон по неудачным попыткам абонентов?',
          reset: 'Перезапустить',
          successResetCount: 'Успешно перезапущено {count} абонентов',
        },
        endCause: {
          abandoned: 'Потерянный',
          timeout: 'Тайм-аут',
          cancel: 'Отменённый',
          success: 'Успешный',
          failed: 'Неудачный',
          missed: 'Пропущенный',
          expired: 'Истекший',
        },
      },

      queues: {
        queues: 'Очередь | Очереди',
        allQueues: 'Все очереди',
        type: 'Тип',
        state: 'Состояние',
        active: 'Активная',
        notActive: 'Не активная',
        activeCalls: 'Активные звонки',
        waiting: 'Ожидание',
        ringtone: 'Мелодия',
        priority: 'Приоритет',
        blacklist: 'Стоп лист',
        newQueue: 'Новая очередь',
        newQueueDescription: 'Типы очередей',
        outboundIVRQueue: 'Исходящая IVR-очередь',
        outboundIVR: 'Исходящий IVR',
        outboundIVRQueueDescription: 'Исходящий IVR позволяет организации автоматически привлекать клиентов по нескольким каналам, таким как автоматические голосовые вызовы, SMS-сообщения, электронная почта или сообщения в социальных сетях с использованием персонализированных уведомлений.',
        inboundQueue: 'Входящая очередь',
        inboundQueueDescription: 'Входящая очередь: звонящие находятся в режиме ожидания в очереди, чтобы поговорить с живым оператором.',
        offlineQueue: 'Оффлайн очередь',
        offlineQueueDescription: 'Автономная очередь дает возможность звонящим  прекратить ожидание на удержании, ввести свой номер телефона и получить обратный вызов.',
        previewDialer: 'Превью обзвон',
        previewDialerDescription: 'Инициатором звонка выступает оператор. Предварительный просмотр контакта перед автоматическим набором номера.',
        progressiveDialer: 'Прогрессивный обзвон',
        progressiveDialerDescription: 'Исходящая кампания с резервированием оператора.',
        predictiveDialer: 'Предиктивный обзвон',
        predictiveDialerDescription: 'Исходящая кампания без предварительного резервирования оператора для максимального сокращения времени ожидания звонка.',
        chatInboundQueue: 'Входящая очередь чатов',
        chatInboundQueueDescription: 'То же самое, что и Входящая очередь, но с чатами',
        inboundJobQueue: 'Входящая очередь заданий',
        inboundJobQueueDescription: 'Входящая очередь заданий',
        outboundJobQueue: 'Исходящая очередь заданий',
        outboundJobQueueDescription: 'Исходящая очередь заданий',
        strategy: 'Стратегия',
        timezone: 'Часовая зона',
        callLimit: 'Максимальное количество одновременных звонков',
        variables: 'Переменные',
        members: 'Абонент | Абоненты',
        params: 'Параметры дозвона',
        discardAbandonedAfter: 'Кол-во секунд до полного удаления абонента',
        maxIdleAgent: 'Ожидание ответа оператора',
        maxIdleClient: 'Ожидание ответа абонента',
        timeoutWithNoAgents: 'Время ожидания, когда нет агентов',
        timeBaseScore: 'Рейтинг звонка',
        endless: 'Не прекращать дозвон',
        stickyAgent: 'Липкость',
        stickyAgentSec: 'Время ожидания липкости (сек)',
        varKey: 'Ключ',
        varVal: 'Значение',
        endCause: 'Причина прекращения',
        offeringAt: 'Перезвонить в',
        destination: 'Назначение | Назначения',
        expire: 'Истекает',
        originateTimeout: 'Длительность ожидания',
        maxAgentLine: 'Количество одновременных звонков на оператора',
        maxAgentLose: 'Максимальное количество непринятых звонков для прекращения донабора линий',
        minAttempts: 'Количество попыток для перехода в предиктивный режим',
        maxAbandonedRate: 'Разрешенный % потерянных звонков',
        abandonRateAdjustment: 'Корректировка потерянных звонков',
        playbackSilence: 'Тишина перед проигрыванием аудиофайла (мс.)',
        targetAbandonedRate: 'Target abandoned rate',
        maxWaitTime: 'Максимальное время ожидания',
        maxWaitingSize: 'Максимальное количество ожидания',
        waitBetweenRetries: 'Время между попытками',
        waitBetweenRetriesDesc: 'Сортировка новых попыток по спаданию',
        strictCircuit: 'Strict circuit',
        retryAbandoned: 'Возобновлять потерянных абонентов',
        playbackFile: 'Проиграть файл',
        timeout: 'Время перерыва между звонками',
        maxNumberOfRetry: 'Максимальное количество попыток звонков',
        minDuration: 'Минимально успешная длительность звонка',
        maxAttempts: 'Максимальное количество попыток',
        waitForResultStatus: 'Ожидание результата звонка',
        bucketPriority: 'Приоритет',
        amd: 'AMD',
        enabled: 'Включить автоопределение',
        ai: 'Искусственный интеллект',
        positiveLabels: 'Положительные теги',
        aiPlayback: 'Проиграть файл',
        allowNotSure: 'Неопределенные',
        silenceNotSure: 'Тишина',
        allowGreetingAgent: 'Разрешить приветствие',
        preSchema: 'Схема предварительного набора',
        afterSchema: 'Схема посленабора',
        formSchema: '',
        maxCalls: 'Лимит задач',
        maxCallsHint: 'Если значение равно 0, дайлер не будет звонить',
        recordings: 'Запись',
        recordAll: 'Начать запись после ответа',
        perNumbers: 'Инициация для каждого номера',
        maxWordLength: 'Максимальная продолжительность предложения (мс)',
        maxNumberOfWords: 'Максимальное кол-во слов в приветствии',
        betweenWordsSilence: 'Продолжительность тишины между словами (мс)',
        minWordLength: 'Минимальная продолжительность непрерывного звука голоса (мс)',
        totalAnalysisTime: 'Максимальное время для распознавания (мс)',
        silenceThreshold: 'Максимальная продолжительность тишины между словами (мс)',
        afterGreetingSilence: 'Продолжительность тишины после приветствия (мс)',
        greeting: 'Максимальная длина приветствия (мс)',
        initialSilence: 'Максимальная продолжительность тишины перед приветствием (мс)',
        statisticTime: 'Интервал пересчета',
        communications: 'Тип связи',
        processing: {
          processing: 'Обработка',
          enabled: 'Ожидание результата задачи',
          formSchema: 'Схема динамической обработки',
          sec: 'Время обработки (сек)',
          renewalSec: 'Предложить продление после (сек)',
        },
        queueStrategy: {
          fifo: 'FIFO (Первый зашёл Первый вышел)',
          lifo: 'LIFO (Последний зашёл Первый вышел)',
          strict_fifo: 'Strict FIFO (Первый зашёл Первый вышел)',
        },
        time: {
          t15m: '15 мин',
          t30m: '30 мин',
          t45m: '45 мин',
          t1h: '1 ч',
          t3h: '4 ч',
          t6h: '6 ч',
        },
        logs: {
          logs: 'Лог | Логи',
          destination: 'Назначение',
          offeringAt: 'Распределение',
          joinedAt: 'Начало',
          leavingAt: 'Конец',
          duration: 'Длительность',
          viewNumber: 'Номер',
          result: 'Результат',
          attempts: 'Попытка',
          resultName: {
            abandoned: 'Abandoned',
            cancel: 'Cancel',
            success: 'Success',
            failed: 'Failed',
            missed: 'Missed',
            timeout: 'Timeout',
            endless: 'Endless',
            transferred: 'Transferred',
          },
        },
        hooks: {
          hooks: 'Хук | Хуки',
          event: 'Событие',
          eventTypes: {
            joined: 'Абонент вошел в очередь',
            answered: 'Принят оператором',
            offering: 'Распределение на оператора',
            bridged: 'Соединение абонента с оператором',
            missed: 'Пропущенный оператором',
            leaving: 'Абонент вышел из очереди',
            processing: 'Постобработка',
            active: 'Активное действие',
          },
        },
      },

      res: {
        res: 'Ресурс | Ресурсы',
        allRes: 'Все ресурсы',
        reserve: 'Резервный',
        searchPlaceholder: 'название, шлюз..',
        cps: 'CPS',
        limit: 'Лимит',
        cidType: 'CID Type',
        ignoreEarlyMedia: 'Ignore Early Media',
        numbers: 'Номер | Номера',
        displayNumbers: 'Номер для отображения',
        failure: 'Сбой',
        maxErrors: 'Максимальное количество ошибок',
        errorCodes: 'Коды ошибок',
        patterns: 'Шаблон исходящего номера',
        priority: 'Приоритет',
        reserveResource: 'Резервный ресурс',
        failureDialDelay: 'Задержка при ошибке набора',
      },

      resGroups: {
        resGroups: 'Группа ресурсов | Группы ресурсов',
        allResGroups: 'Все группы ресурсов',
        searchPlaceholder: 'название группы ресурсов..',
        addResGroup: 'Добавить группу ресурсов',
        strategy: 'Стратегия',
        timerange: 'Интервал',
        timerangeFrom: 'ОТ',
        timerangeTo: 'До',
      },
    },
    integrations: {
      integrations: 'Интеграции',

      storage: {
        storage: 'Хранилища',
        allStorages: 'Все хранилища',
        type: 'Тип',
        maxSize: 'Объем хранилища (GB)',
        expireDays: 'Срок хранения (дней)',
        priority: 'Приоритет',
        newStorage: 'Новое хранилище',
        newStorageDescription: 'Описание нового хранилища',
        local: 'Local',
        s3: 'Amazon S3',
        digitalOcean: 'Digital Ocean Spaces',
        backblaze: 'Backblaze B2',
        dropbox: 'Dropbox',
        drive: 'Google Drive',
        configuration: 'Конфигурация',
        pathPattern: 'Шаблон пути',
        localFolder: 'Local folder',
        s3Key: 'Key ID',
        s3Access: 'Access Key',
        bucket: 'Корзина',
        region: 'Площадка',
        oceanKey: 'Key ID',
        oceanAccess: 'Access Key',
        backblazeAccount: 'Account ID',
        backblazeKey: 'Application key',
        backblazeBucketId: 'Bucket Id',
        dropboxKey: 'Access Token',
        driveFolder: 'Folder name',
        driveEmail: 'Google Email',
        driveKey: 'Private key',
        s3Endpoint: 'Endpoint',
      },

      cognitiveProfiles: {
        cognitiveProfiles: 'Голосовой профиль | Голосовые профили',
        properties: {
          region: 'Регион',
          locale: 'Язык',
        },
      },
      emailProfiles: {
        emailProfiles: 'Email профиль | Email профили',
        mailbox: 'Почтовый ящик',
        smtpPort: 'SMTP Порт',
        imapPort: 'IMAP Порт',
        smtpHost: 'SMTP Хост',
        imapHost: 'IMAP Хост',
        fetchInterval: 'Интервал обновления',
      },

      importCsv: {
        importCsv: 'Импорт CSV файла | Импорты CSV файлов',
        source: 'Назначение импорта',
        settings: 'Настройки',
        mappings: 'Маппинги полей',
        mappingsHint: 'В этой секции необходимо указать названия колонок CSV файла в полях ниже в соответствии с их заголовками',
        columnHeader: 'Колонка {name}',
        members: {
          clearMembers: 'Удалить существующих абонентов',
        },
      },

      tokens: {
        tokens: 'Токен | Токены',
        allTokens: 'Все токены',
        expires: 'Истекает',
        popupMessage: 'Загрузите файл ключа или скопируйте его. Если вы не загрузите файл ключа сейчас, вы не сможете извлечь его еще раз. Чтобы повысить безопасность, храните ключ в надежном месте и не делитесь им.',
        tokenCreated: 'Ваш токен успешно создан!',
      },

      triggers: {
        triggers: 'Триггер | Триггеры',
        allTriggers: 'Все триггеры',
        type: 'Тип',
        schema: 'Схема',
        newTrigger: 'Новый триггер',
        newTriggerDescription: 'Choose a template to get going or start from scratch with a blank canvas',
        onEvent: 'On event',
        onEventDescription: 'An event-based JSON Schema scheduler',
        cron: 'At time',
        atTimeDescription: 'A time-based JSON Schema scheduler',
        event: 'Event',
        expression: 'Выражение',
        timeout: 'Задержка',
        logs: {
          logs: 'История запуска',
          startedAt: 'Начало',
          result: 'Результат',
          resultName: {
            idle: 'Неактивний',
            active: 'Активний',
            done: 'Завершенный',
            error: 'Ошибка',
          },
        },
      },
    },
    pagination: {
      rowsPerPage: 'Количество строк на странице',
    },
  },

  utils: {
    downloadFilesBtn: {
      downloadFiles: 'Скачать файлы',
      filesLoaded: 'Файлов скачано',
      zippingProgress: 'Прогресс архивации',
    },
  },

  errorPages: {
    goToHome: 'Вернуться Домой',
    page403: {
      title: 'Нет доступа',
      text: 'Извините, у вас недостаточно прав доступа для просмотра этой страницы.',
    },
    page404: {
      title: 'Похоже, вы потерялись',
      text: 'Извините, мы не можем найти то, что вы ищете',
    },
  },

  icon: {
    docs: 'Документация',
    logout: 'Выйти',
    account: 'Аккаунт',
    appNavigator: 'Навигатор приложений',
    settings: 'Настройки',
  },

  iconHints: {
    upload: 'Загрузить',
    reload: 'Обновить',
    edit: 'Редактировать',
    delete: 'Удалить',
    deleteAll: 'Удалить все объекты',
    deleteSelected: 'Удалить {count} выбранных объектов',
    deleteFiltered: 'Удалить все отфильтрованные объекты',
    generate: 'Создать',
    add: 'Добавить',
    history: 'История',
    download: 'Скачать',
    downloadAll: 'Скачать все',
    draggable: 'Перетащить',
    play: 'Играть',
    pause: 'Пауза',
    resume: 'Возобновить',
    close: 'Закрыть',
    volume: 'Громкость',
    stop: 'Остановить',
    members: 'Абоненты',
    moveUp: 'Передвинуть вверх',
    moveDown: 'Передвинуть вниз',
    nextPage: 'Следующая страница',
    prevPage: 'Предыдущая страница',
    expand: 'Развернуть',
    collapse: 'Свернуть',
  },
};
